import { toTheme } from "@theme-ui/typography";
import typography from "typography-theme-stow-lake";
import merge from "deepmerge";
import "./src/styles/base.css";

const theme = merge(toTheme(typography), {
  styles: {
    root: {
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      blockquote: {
        background: "#ebebeb",
        p: 3,
        borderRadius: 6,
      },
    },
  },
  colors: {
    text: "#222B45",
    background: "#F8F8F8",
    primary: "#0091FF",
    secondary: "yellow",
    muted: "#e2e8f0",
    textMuted: "#718096",
    shadow: "#E6E6E6",
    danger: "#FFCDD2",
    dangerDark: "#D32F2F",
  },
  layout: {
    container: {
      width: 1080,
    },
    payment: {
      maxWidth: 600,
      mx: "auto",
      py: 3,
      px: 2,
    },
    blogImage: {
      width: "100%",
      height: [400, null, null, 600],
      overflow: "hidden",
      my: 2,
      // border: "1px solid red",
      img: {
        objectFit: "cover",
        width: "100%",
        height: "auto",
      },
    },
    blog: {
      maxWidth: 800,
      m: "0 auto",
      px: [3, null, null, 0],
    },
    small: {
      width: 500,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  links: {
    nav: {
      fontSize: 0,
      fontWeight: "body",
      p: 2,
      textTransform: "capitalize",
    },
  },
  text: {
    heading: {
      lineHeight: 1.5,
      fontSize: 4,
      fontWeight: "heading",
    },
    underlined: {
      textDecoration: "underline",
      fontSize: 0,
    },
    small: {
      fontSize: 0,
    },
    label: {
      fontSize: 14,
      fontWeight: "bold",
      mb: "2px",
    },
    copy: {
      fontSize: "10px",
      lineHeight: 1.5,
      color: "#6D7278",
      fontWeight: "400",
    },
  },
  buttons: {
    primary: {
      px: 2,
      py: 0,
      height: 45,
      diplay: "block",
      width: "100%",
      bg: "primary",
      fontWeight: "600",
      fontSize: 1,
      // fontSize: 0,
      // fontWeight: 600,
    },
    link: {
      background: "transparent",
      px: 2,
      py: 0,
      height: 40,
      color: "textMuted",
    },
  },
  input: {
    normal: {
      fontSize: "16px",
      height: 45,
      border: "1px solid #d7d7d7",
      borderRadius: "6px",
      "&::placeholder": {
        color: "#aab7c4",
      },
    },
  },
  images: {
    brand: {
      height: 32,
      display: "inline-block",
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 60,
    mb: 4,
  },
  messages: {
    error: {
      bg: "danger",
      p: 2,
      borderRadius: 6,
      mb: 2,
      fontSize: 0,
      color: "dangerDark",
    },
  },
});

export default theme;
