module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"yellow","showSpinner":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://uybfd7owe1.execute-api.eu-central-1.amazonaws.com/dev/graphql","headers":{"x-api-key":"pub_98e221c3-c3ed-4a05-9bcc-590bd20f674f","client-name":"InnerCity Foodbank","client-version":"1.0.0"},"credentials":"same-origin"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
