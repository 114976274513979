// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pay-tsx": () => import("./../../../src/pages/pay.tsx" /* webpackChunkName: "component---src-pages-pay-tsx" */),
  "component---src-pages-payment-complete-tsx": () => import("./../../../src/pages/payment-complete.tsx" /* webpackChunkName: "component---src-pages-payment-complete-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-v-1-pay-tsx": () => import("./../../../src/pages/v1/pay.tsx" /* webpackChunkName: "component---src-pages-v-1-pay-tsx" */),
  "component---src-pages-v-2-pay-tsx": () => import("./../../../src/pages/v2/pay.tsx" /* webpackChunkName: "component---src-pages-v-2-pay-tsx" */)
}

